// import React, { useRef, useEffect,useState} from "react";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./coursepagestyle.css";
import CourseSyllabus from "./CourseSyllabus";
import User from "./User";
//import { useUserContext } from '../CourseLogin/UserContext';
import Footer from '../../Footer/Footer';
import web3button from "../../../assets/Images/web3button.svg";
import coursesbutton from "../../../assets/Images/courses_red.svg";
import navContributors from "../../../assets/Images/nav_contri.svg";
import loginSignup from "../../../assets/Images/loginSignup.svg";



const CoursePage = () => {
  const [courseType, setCourseType] = useState('Frontend');
  const jwtToken = localStorage.getItem('jwtToken');
  const USN = localStorage.getItem('USN');
  const fullName = localStorage.getItem('fullName');
  const firstName = fullName ? fullName.split(' ')[0] : 'User';
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('USN');
    localStorage.removeItem('fullName');
    localStorage.removeItem('userName');
    localStorage.removeItem('college');
    localStorage.removeItem('__v');
    navigate('/courselogin');
  };
  console.log("courseType in coursePage",courseType);

  return (
    <>
    <div style={{ backgroundColor: '#FFFFFF', minHeight: '100vh' }}>
    <div className="nav-bar-container">
          <div className="page-navigation-container1">
          <div className="nav-button-container">
          <a href='/'>
          <img src={web3button} alt="web3button"/>
          </a>
          </div>
          <div className="nav-button-container">
            <a href='/course'>
          <img src={coursesbutton} alt="coursesButton" />
          </a>
          </div>
          </div>
          <div className="coursepage-navigation-container">
          <div className="nav-button-container">
          <a href='/'>
          <img className='nav-contributors-button' src={navContributors} alt="web3button"/>
          </a>
          </div>
          {jwtToken && USN ? (
                <div className="nav-user-button-container">
                  <button className="user-button">Hi, {firstName}</button>
                  <button className="logout-button" onClick={handleLogout}>LOGOUT</button>
                </div>
                
              ) : (
                <div className="nav-button-container">
                <a href='/courselogin'>
                  <img className='nav-loginSignup-button' src={loginSignup} alt="loginSignup" />
                </a>
                </div>
              )}
          </div>
        </div>
    <User courseType={courseType}/>
    <CourseSyllabus setCourseType={setCourseType} />
    </div>
    <Footer/>

    </>
  );
};

export default CoursePage;
