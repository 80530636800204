import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./CourseSyllabus.css";
//import { useUserContext } from "../CourseLogin/UserContext";
import SeeAllContent from "../../../assets/Images/seeAllContent.svg";
import bullet from "../../../assets/Images/bullet.svg";
import newBullet from "../../../assets/Images/currentbullet.svg";
import joinProduction from "../../../assets/Images/joinProduction.svg";
import completedBulletSrc  from "../../../assets/Images/done_all.svg";
import completedStatus from "../../../assets/Images/completed.svg";
import completedIcon from "../../../assets/Images/completedIcon.svg"
import flag from "../../../assets/Images/flag.svg"

const CourseSyllabus  = ({ setCourseType } ) => {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  const leftProgressRefs = useRef([]);
  const [selectedCourseIndex, setSelectedCourseIndex] = useState(0);
  const [courses, setCourses] = useState([]);
  const [activeBox, setActiveBox] = useState('Frontend');
  const [spinner, setSpinner] = useState(true);
  //const { USNnumber } = useUserContext();
  

  const apiBaseUrl = 'https://auth.web3onwards.com';
  const defaultUSNnumber ='1W3O2024';
  const defaultjwt = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NjlmNGEzMDJjN2NjMzcwMzYyMjNmOGYiLCJVU05udW1iZXIiOiIxVzNPMjAyNCIsImlhdCI6MTcyMTcxNTI4OH0.7DpOPyaq2LI09eawOJFjXSW7Bj-9ANkQ6WilsDfmABY';

  const fetchCourses = async (courseType) => {

    const USNnumber = localStorage.getItem("USN") || defaultUSNnumber; 
    const jwt = localStorage.getItem('jwtToken') || defaultjwt;

    const url = new URL(`/course/${courseType}`, apiBaseUrl);
    url.searchParams.append('USNnumber', USNnumber); 
  
    try {
      setSpinner(true)
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${jwt}`, // Include the JWT in the Authorization header
        }
      });
      if (response.ok) {
        const jsonData = await response.json();
        setCourses(jsonData.courseTypeDetails);
      } else {
        throw new Error(`Failed to fetch courses: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }finally{setSpinner(false)
    console.log(loading)}
  };

  useEffect(() => {
    fetchCourses('Frontend');
    
  }, []);
  // useEffect(() => {
  //   console.log("User USN:", USNnumber); 
  // }, [USNnumber]);

  const isCourseCompleted = (course) => {
    return course.courseCompletedPercentage === 100;
  };

  const handleCourseClick = (index) => {
    const course = courses[index];
    if (!isCourseCompleted(course)) {
      setSelectedCourseIndex(index);
    } else {
      // If a completed course is clicked, do not change the selected index
      if (index !== selectedCourseIndex) {
        setSelectedCourseIndex(index);
      }
    }
  };

  const handleBoxClick = (courseType) => {
    setActiveBox(courseType);
    setCourseType(courseType); 
    fetchCourses(courseType);
    // props.onCourseTypeChange(courseType);
  };
  // Utility function to format minutes into hours and minutes
const formatTime = (minutes) => {
  if (minutes < 60) {
    return `${minutes}mins`;
  } else {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}hr ${mins > 0 ? `${mins}mins` : ''}`;
  }
};
const getBackgroundColor = (level) => {
  switch (level) {
    case "Beginner":  
      return "#15b525";
    case "Intermediate":
      return "#E87826";
    case "Advanced":
      return "#FF3B3B";
    default:
      return "#15b525"; // Default color
  }
};

const handleHeaderClick = (courseId, courseName, level, courseType) => {
  const USNnumber = localStorage.getItem('USN');
  const jwtToken = localStorage.getItem('jwtToken');

  if (USNnumber && jwtToken) {
    // Log courseId, courseName, and level information to the console
    console.log("Navigating with Course ID:", courseId, "Course Name:", courseName, "and Level:", level.level, "Course Type:", courseType);

    // Navigate to /course-description and pass courseId, courseName, and levelId
    navigate('/course-description', {
      state: {
        courseId: courseId,
        courseName: courseName,
        level: level,
        courseType: courseType
      }
    });
  } else {
    navigate('/courselogin');
  }
};

const handleSeeAllContentClick = () => {
  const USNnumber = localStorage.getItem('USN');
  const jwtToken = localStorage.getItem('jwtToken');

  if (courses.length > 0 && USNnumber && jwtToken) {
    const firstCourse = courses[0];
    navigate('/course-description', {
      state: {
        courseId: firstCourse.courseId,
        courseName: firstCourse.courseName,
        level: "Beginner",
        courseType: activeBox,
      },
    });
  }else {
    navigate('/courselogin');
  }
};
useEffect(() => {
  setTimeout(() => setSpinner(false), 2000);
}, []);



  const progressBarStyles = {
    height: "0.75rem",
    backgroundColor: "#2CBC3B",
    backgroundImage:
      "linear-gradient(135deg, rgba(49, 236, 61, 0.3) 25%, transparent 25%, transparent 50%, rgba(49, 236, 61, 0.3) 50%, rgba(49, 236, 61, 0.3) 75%, transparent 75%, transparent)",
    backgroundSize: "1.5rem 1.5rem",
    color: "white",
    width: "0%",
    transition: "width 1s ease-in",
  };

  return (
    <>
      <div className="course-syllabus-container">
        <div className="wrapper">
          <div className="box-1">
            <div className="box-container">
              <div
                className={`box ${activeBox === "Frontend" ? "active" : ""}`}
                onClick={() => handleBoxClick("Frontend")}
              >
                Frontend Dev
              </div>
              <div
                className={`box ${activeBox === "Backend" ? "active" : ""}`}
                onClick={() => handleBoxClick("Backend")}
              >
                Backend
              </div>
              <div
                className={`box ${activeBox === "Blockchain" ? "active" : ""}`}
                onClick={() => handleBoxClick("Blockchain")}
              >
                Blockchain
              </div>
              {/* <div
                className={`box ${activeBox === "UIUX" ? "active" : ""}`}
                onClick={() => handleBoxClick("UIUX")}
              >
                UI UX Designer
              </div> */}
            </div>
          </div>
          <div className="box-2">
            {!spinner?<div className="box-container">

<div className="course-syllabus-left-box">
<div className="course-outline-box1">
<div className="course-outline-box">
    {courses.map((course, index) => (
<div key={course._id} className={`component ${selectedCourseIndex === index ? 'selected-component' : ''} ${isCourseCompleted(course) ? 'completed' : ''}`}>
<div className="bullet" onClick={() => handleCourseClick(index)}>
<img src={isCourseCompleted(course) ? completedBulletSrc : (selectedCourseIndex === index ? newBullet : bullet)} 
   alt={`${course.courseName} Bullet`}
   className={selectedCourseIndex === index ? 'new-bullet' : ''} />
</div>
  <div className="component-content">
  <div className="component-title-box">
  <div className="component-title" onClick={() => handleCourseClick(index)}>
    {course.courseName}
  </div>
  </div>
  <div className="component-description-box">
  <div className="component-description">{course.Description}</div>
  </div>
  {selectedCourseIndex === index && (
<>
{course.courseCompletedPercentage === 0 ? (
<div className="yet-to-start-container">
<img src={flag} alt="Completed Icon"/>
<div className="yet-to-start">
<h4 className="yet-to-start-text">Yet to start</h4>
</div>
</div>

) : isCourseCompleted(course) ? (
<div className="completed-label">
<img src={completedStatus} alt="Completed Status" />
</div>
) : (
<div className="progress-container" style={{
width: "100%",
height: "0.75rem",
border: "1.5px solid black",
position: "relative"
}}>
<div className="progress-bar" 
    ref={(el) => (leftProgressRefs.current[index] = el)} 
    style={{ ...progressBarStyles, width: `${course.courseCompletedPercentage}%` }}>
</div>
</div>
)}
</>
)}
  </div>
  </div>
  ))}
  </div>
  </div>
  <div className="join-button-container">
    <img src={joinProduction} alt="Join Production"></img>
  </div>
</div>
<div className="right-box">
  <div className="course-box">
    <div className="box">
      <div className="course-text-container">
      <div className="course-text">SECTIONS</div>
      </div>
      <div className="course-level-box-container">
      {selectedCourseIndex !== null && courses[selectedCourseIndex] && courses[selectedCourseIndex].Levels.map((level, levelIndex) => (
        <div key={level._id} className="course-level-box"style={{ border: level.percentageCompleted === 100 ? `2px solid ${getBackgroundColor(level.level)}` : '1px solid black' }}>
        <div className="course-level-inner-box" onClick={() => handleHeaderClick(courses[selectedCourseIndex].courseId,courses[selectedCourseIndex].courseName, level.level, activeBox)}> 
          <div className="header-text-container">
          <div className="header-text">{level.levelName}</div>
          <div className="course-level-box-label" style={{ backgroundColor: getBackgroundColor(level.level) }}>
          {level.level}
        </div>
          </div>
          <div className="para-text-container">
          <div className="para-text">{level.levelDescription}</div>
          </div>
          <div className="task-box">
            <div className="b-1-container">
            <div className="b-1" style={{ backgroundColor: level.percentageCompleted === 100 ? getBackgroundColor(level.level) : '#e0e0e0', color: level.percentageCompleted === 100 ? 'white' : 'black' }}>{level.totalTasks} Tasks</div>
              <div className="b-1"style={{ backgroundColor: level.percentageCompleted === 100 ? getBackgroundColor(level.level) : '#e0e0e0', color: level.percentageCompleted === 100 ? 'white' : 'black' }}>{formatTime(level.levelMinutes)}</div>

            </div>
            <div className="progress-text">
            {level.percentageCompleted === 0 ? "Yet to start" : (level.percentageCompleted === 100 ? (
             <>
             <img src={completedIcon} alt="Completed Icon" style={{ marginRight: '0.5rem',height:'1.5rem' }} />
             Completed
             </> 
              ) : `Progress ${level.percentageCompleted}%`)}
            </div>
          </div>
        </div> 
        {level.percentageCompleted !== 100 && (
        <div className="course-level-progress"
        style={{ width: `${level.percentageCompleted}%`, borderColor: getBackgroundColor(level.level), transition: 'width 1s ease-in-out' }}></div>
        )}
      </div>
      
      ))}
      </div>
    </div>

    <img
  style={{ width: "100%", cursor: "pointer" }}
  src={SeeAllContent}
  alt=""
  onClick={handleSeeAllContentClick}
/>
  </div>
</div>
</div>:<div
        id="spinner"
        className="course-spin-container"
        style={{ display: !spinner && "none" }}
      >
        <div className="course-loading"></div>
      </div>}
            
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseSyllabus;
